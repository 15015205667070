import React from "react";

function BaseEntry({
  name,
  label,
  children,
}: {
  name?: string;
  label: string;
  children: React.ReactNode;
}) {
  return (
    <div className="row my-1 w-100">
      <div className="col-sm">
        <label htmlFor={name}>{label}</label>
      </div>
      <div className="col-sm-2">{children}</div>
    </div>
  );
}

type InputEntryProps = { label: string } & React.ComponentProps<"input">;
function InputEntry({
  label,
  name,
  required = true,
  autoComplete = "off",
  ...props
}: InputEntryProps) {
  return (
    <BaseEntry label={label} name={name}>
      <input
        name={name}
        required={required}
        autoComplete={autoComplete}
        {...props}
      />
    </BaseEntry>
  );
}

type SelectEntryProps = { label: string } & React.ComponentProps<"select">;
function SelectEntry({
  label,
  name,
  required = true,
  autoComplete = "off",
  ...props
}: SelectEntryProps) {
  return (
    <BaseEntry label={label} name={name}>
      <select
        name={name}
        required={required}
        autoComplete={autoComplete}
        {...props}
      />
    </BaseEntry>
  );
}

type TextAreaEntryProps = { label: string } & React.ComponentProps<"textarea">;
function TextAreaEntry({
  label,
  name,
  required = true,
  autoComplete = "off",
  ...props
}: TextAreaEntryProps) {
  return (
    <BaseEntry label={label} name={name}>
      <textarea
        name={name}
        required={required}
        autoComplete={autoComplete}
        {...props}
      />
    </BaseEntry>
  );
}

export type EntryProps =
  | ({ inputType?: "input" } & InputEntryProps)
  | ({ inputType?: "select" } & SelectEntryProps)
  | ({ inputType?: "textarea" } & TextAreaEntryProps);

export function Entry({ inputType = "input", ...props }: EntryProps) {
  switch (inputType) {
    case "input":
      return <InputEntry {...(props as InputEntryProps)} />;
    case "select":
      return <SelectEntry {...(props as SelectEntryProps)} />;
    case "textarea":
      return <TextAreaEntry {...(props as TextAreaEntryProps)} />;
    default:
      return <InputEntry {...(props as InputEntryProps)} />;
  }
}
