import React from "react";

export type Size = [number, number];

type SizeGroupProps = {
  entryData: Partial<Size>;
  index: number;
  updateSizes: (index: number, newPattern: Partial<Size>) => void;
};
export function SizesGroup({ entryData, index, updateSizes }: SizeGroupProps) {
  const handleChange =
    (dimension: "width" | "height") => (event: React.ChangeEvent) => {
      const { value: newValue } = event.target as HTMLInputElement;
      const [oldWidth, oldHeight] = entryData;
      const newEntry =
        dimension === "width"
          ? ([Number(newValue), oldHeight] as const as Size)
          : ([oldWidth, Number(newValue)] as const as Size);

      updateSizes(index, newEntry);
    };
  return (
    <div className="my-2">
      <div className="row">
        <div className="col-1">Width:</div>
        <div className="col-5">
          <input
            type="number"
            required={true}
            min={1}
            value={entryData?.[0] ?? ""}
            step={1}
            onChange={handleChange("width")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-1">Height:</div>
        <div className="col-5">
          <input
            type="number"
            required={true}
            min={1}
            value={entryData?.[1] ?? ""}
            step={1}
            onChange={handleChange("height")}
          />
        </div>
      </div>
    </div>
  );
}

type SizesManagerProps = {
  sizes: Partial<Size[]>;
  updateFormData: (key: string, value: any) => void;
};

export function SizesManager({ sizes, updateFormData }: SizesManagerProps) {
  const addSize = () => {
    updateFormData("sizes", [
      ...sizes,
      [undefined, undefined] as unknown as Size,
    ]);
  };
  const updateSizes = (index: number, newEntry: Partial<Size>) => {
    const sizesArray = [...sizes];
    sizesArray[index] = newEntry as Size;
    updateFormData("sizes", sizesArray);
  };
  const deleteSize = () => {
    updateFormData("sizes", sizes.slice(0, -1));
  };
  const [numSizes, setNumSizes] = React.useState(0);
  React.useEffect(() => {
    setNumSizes(sizes.length);
  }, [sizes]);
  const increaseSizes = () => {
    addSize();
    setNumSizes((existing) => existing + 1);
  };
  const decreaseSizes = () => {
    // If last one is not empty, alert
    const lastSizeEntry = sizes[sizes.length - 1];
    if (lastSizeEntry?.[0] || lastSizeEntry?.[1]) {
      const confirmation = window?.confirm(
        "There's input in the size. Are you sure?"
      );
      if (!confirmation) {
        return;
      }
    }

    deleteSize();
    setNumSizes((existing) => Math.max(0, existing - 1));
  };
  let InnerSizes = [];
  for (let index = 0; index < numSizes; index++) {
    const entryData =
      index <= sizes.length
        ? (sizes[index] as Size)
        : ([undefined, undefined] as unknown as Size);

    InnerSizes.push(
      <SizesGroup
        key={`size-${index}`}
        entryData={entryData}
        index={index}
        updateSizes={updateSizes}
      />
    );
  }
  return (
    <div className="sizesManager">
      <button onClick={increaseSizes}>Add new size</button>
      <button onClick={decreaseSizes}>Remove last size</button>
      {InnerSizes}
    </div>
  );
}
