import React from "react";
import { useAsync } from "react-async";
import { Entry } from "../Form/Entry";
import {
  sendStatusRequestToOdyssey,
  StatusRequestResponse,
} from "../Form/functions";
import { SubmitState } from "../Form/SubmitState";

export function Form() {
  const [formData, setFormData] = React.useState<{
    taskId?: string;
  }>({});

  const updateFormData = (key: string, value: any) => {
    setFormData((currentState) => {
      return { ...currentState, [key]: value };
    });
  };

  const clearFormData = () => {
    setFormData({});
  };

  const handleInputChange = (event: React.ChangeEvent) => {
    const target = event.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    const value =
      target.type === "checkbox"
        ? (target as HTMLInputElement).checked
        : target.value;
    const name = target.name;

    updateFormData(name, value);
  };

  const asyncHandler = useAsync({
    deferFn: async () => {
      if (!formData.taskId) {
        return;
      }
      const response = await sendStatusRequestToOdyssey(formData.taskId);
      if (!response.ok) {
        console.error("Response not ok");
        console.error(response.status);
      }
      clearFormData();
      return response.json() as unknown as StatusRequestResponse;
    },
  });

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    if (!asyncHandler.isPending) {
      asyncHandler.run();
    }
  };

  return (
    <>
      <form className="w-50" autoComplete="off" onSubmit={submitHandler}>
        <Entry
          onChange={handleInputChange}
          name="taskId"
          label="Task ID"
          value={formData.taskId ?? ""}
        />
        {!asyncHandler.isPending && <input type="submit" className="mx-1" />}
        <SubmitState
          asyncHandler={asyncHandler}
          warning={Boolean(asyncHandler.data?.status.incompleteItems.length)}
        />
      </form>
    </>
  );
}
