import React from "react";
import { AsyncState } from "react-async";

export function SubmitState({
  asyncHandler,
  warning = false,
}: {
  asyncHandler: AsyncState<any>;
  warning?: boolean;
}) {
  if (asyncHandler.isInitial) {
    return <span className="badge rounded-pill bg-secondary">Ready</span>;
  }
  if (asyncHandler.isPending) {
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  if (asyncHandler.error) {
    return (
      <>
        <span className="badge rounded-pill bg-danger">Failed.</span>
        <div className="alert alert-danger" role="alert">
          {asyncHandler.error.message}
        </div>
      </>
    );
  }
  if (warning) {
    return (
      <>
        <span className="badge rounded-pill bg-warning">Warning.</span>
        <div className="alert alert-warning" role="alert">
          {asyncHandler.data}
        </div>
      </>
    );
  }

  if (asyncHandler.data) {
    return (
      <>
        <span className="badge rounded-pill bg-success">Success!</span>
        <div className="alert alert-success w-100" role="alert">
          <pre>{JSON.stringify(asyncHandler.data, undefined, 2)}</pre>
        </div>
      </>
    );
  }
  return (
    <div className="alert alert-warning" role="alert">
      Unknown state. What?
    </div>
  );
}
