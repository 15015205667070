/**** These cannot be imported from lib/constants.ts because React doesn't support it
 */
export const ZEUS_DOMAINS = {
  development: "zeus.aws.arc.pub",
  production: "zeustechnology.com",
  sandbox: "sandbox.zeustechnology.io",
};

// Calculate DEPLOY_ENV with typing
const environments = ["development", "production", "sandbox"] as const;
type EnvironmentType = (typeof environments)[number];

export const DEPLOY_ENV: EnvironmentType = environments.includes(
  process.env.REACT_APP_CDK_ENV as EnvironmentType
)
  ? (process.env.REACT_APP_CDK_ENV as EnvironmentType)
  : "sandbox";

type TaskStatus =
  | "QUEUED"
  | "FAILED_TO_QUEUE"
  | "ACCEPTED"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "PENDING_RETRY"
  | "RETRYING"
  | "RETRY_FAILED"
  | "CRITICAL_ERROR"
  | "DEAD_LETTERED"
  | "UNKNOWN";

export type StatusRequestResponse = {
  taskId: string;
  status: {
    completedPricePoints: number[];
    incompleteItems: { pricePoint: number; status: TaskStatus }[];
  };
};

/**
 * @param {{ taskId: string }} formData containing only one property, `taskId`
 * @returns {Promise<Response>} Resolves to the response of the HTTP request.
 */
export async function sendStatusRequestToOdyssey(
  taskId: string
): Promise<Response> {
  return fetch(
    `https://odyssey.${ZEUS_DOMAINS[DEPLOY_ENV]}/status?taskId=${taskId}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export async function sendStartRequestToOdyssey(formData: {
  [key: string]: any;
}) {
  return fetch(`https://odyssey.${ZEUS_DOMAINS[DEPLOY_ENV]}/start`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(formData),
  });
}

type QueueResponseType = {
  succeeded: { count: number };
  failed: { count: number; tasks: { taskId: string; pricePoint: string }[] };
};

export type OdysseyStartRunResponseType = {
  taskId: string;
  orders: {
    id: string;
    name: string;
    isArchived: boolean;
  }[];
  queueResults: QueueResponseType;
};
