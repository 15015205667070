import React from "react";

import { Form as RequestForm } from "./requests";
import { Form as StatusForm } from "./status";

function App() {
  return (
    <div className="App m-4 container">
      <header className="App-header">
        <h1>Zeus Odyssey internal management</h1>
      </header>
      <hr />
      <section>
        <h2>Status form</h2>
        <StatusForm />
      </section>
      <hr />
      <section>
        <h2>Request form</h2>
        <RequestForm />
      </section>
    </div>
  );
}

export default App;
